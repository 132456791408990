import "./ProfileDd.scss";
import { images } from "../../../../utils/images";
import Dropdown from "react-bootstrap/Dropdown";
import { LuUser2 } from "react-icons/lu";
import { SlArrowRight } from "react-icons/sl";
import { useState, Fragment, useContext, useEffect } from "react";
import ChangePassword from "./changePassword/ChangePassword";
import LogoutPop from "./logoutPop/LogoutPop";
import { motion } from "framer-motion";
import EditProfile from "./editProfile/EditProfile";
import Faq from "./faq/Faq";
import NotificationSetting from "./notificationSetting/NotificationSetting";
import DeleteAccountReason from "./deleteAccountReason/DeleteAccountReason";
import HelpCenter from "./helpCenter/HelpCenter";
import History from "./history/History";
import axios from "axios";
import { baseUrl, getProfile } from "../../../../utils/apiData";
import { webContext } from "../../../../webContext/WebContext";
import ProfilePhoto from "./profilePhoto/ProfilePhoto";
import { MdOutlineEdit } from "react-icons/md";
import DeleteAccountPop from "./deleteAccountPop/DeleteAccountPop";

const ProfileDd = () => {
  const [screen, setScreen] = useState(0);
  const [profile, setProfile] = useState({});
  const [profileLoader, setProfileLoader] = useState(false);
  const [reason, setReason] = useState("");

  const { toastSuccess, toastError } = useContext(webContext);
  const btnData = [
    {
      id: 0,
      icon: images.purchaseHistory,
      title: "Purchase history",
      clickHandler: () => {
        setScreen(1);
      },
    },
    {
      id: 1,
      icon: images.changePassword,
      title: "Change Password",
      clickHandler: () => {
        setScreen(2);
      },
    },
    // {
    //   id: 2,
    //   icon: images.notification,
    //   title: "Notifications",
    //   clickHandler: () => {
    //     setScreen(3);
    //   },
    // },
    {
      id: 3,
      icon: images.faqs,
      title: "FAQs",
      clickHandler: () => {
        setScreen(4);
      },
    },
    {
      id: 4,
      icon: images.helpCenter,
      title: "Help Center",
      clickHandler: () => {
        setScreen(5);
      },
    },
    {
      id: 5,
      icon: images.deleteAccount,
      title: "Delete Account",
      clickHandler: () => {
        setScreen(6);
      },
    },
    {
      id: 6,
      icon: images.logout,
      title: "Logout",
      clickHandler: () => {
        setScreen(7);
      },
    },
  ];

  const getProfileApi = async () => {
    setProfileLoader(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const res = await axios.get(`${baseUrl}/${getProfile}`, { headers });
      if (res?.data?.success) {
        setProfileLoader(false);
        setProfile(res?.data?.data);
      }
    } catch (error) {
      setProfileLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getProfileApi();
  }, []);

  return (
    <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        <LuUser2 />
      </Dropdown.Toggle>

      <Dropdown.Menu className="profile">
        {[0, 7, 11].includes(screen) && (
          <Fragment>
            <div className="user_info">
              <img src={images.profileBg} alt="bg" className="bg" />
              {profileLoader ? (
                <div className="loading">
                  <p>Loading...</p>
                </div>
              ) : (
                <div className="user_content">
                  <div className="user_profile">
                    {profile?.profile ? (
                      <img src={profile?.profile} alt="profile" />
                    ) : (
                      <img src={images.profileAvtar} alt="profile" />
                    )}

                    <button
                      type="button"
                      className="edit"
                      onClick={() => setScreen(10)}
                    >
                      <MdOutlineEdit />
                    </button>
                  </div>
                  <p>{profile?.name || "-"}</p>
                  <p className="t_14">
                    {profile?.address || "-"} , {profile?.country || "-"}
                  </p>
                  <button type="button" onClick={() => setScreen(8)}>
                    Edit Profile
                  </button>
                </div>
              )}
            </div>

            <motion.div
              initial={{ opacity: 0, scaleY: 0.4 }}
              whileInView={{ opacity: 1, scaleY: 1 }}
              transition={{ duration: 0.4, ease: "easeInOut" }}
              className="btns_wrap_box"
            >
              {btnData.slice(profile?.role === "user" ? 0 : 1).map((data) => {
                return (
                  <button
                    type="button"
                    onClick={data.clickHandler}
                    key={data.id}
                    className="profile_menu"
                  >
                    <div className="left">
                      <img src={data.icon} alt="icon" />
                      <p>{data.title}</p>
                    </div>
                    <span>
                      <SlArrowRight />
                    </span>
                  </button>
                );
              })}
            </motion.div>

            {screen === 7 && <LogoutPop setScreen={setScreen} />}
            {screen === 11 && <DeleteAccountPop setScreen={setScreen} reason={reason} />}
          </Fragment>
        )}

        {screen === 1 && <History setScreen={setScreen} />}
        {screen === 2 && <ChangePassword setScreen={setScreen} />}
        {screen === 3 && <NotificationSetting setScreen={setScreen} />}
        {screen === 4 && <Faq setScreen={setScreen} />}
        {screen === 5 && <HelpCenter setScreen={setScreen} />}
        {screen === 6 && (
          <DeleteAccountReason
            reason={reason}
            setReason={setReason}
            setScreen={setScreen}
          />
        )}

        {screen === 8 && (
          <EditProfile
            setScreen={setScreen}
            getProfileApi={getProfileApi}
            currentProfile={profile}
          />
        )}

        {screen === 10 && (
          <ProfilePhoto
            setScreen={setScreen}
            currentProfile={profile}
            getProfileApi={getProfileApi}
          />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default ProfileDd;
