import "./DeleteProductPopup.scss";
import { images } from "../../../../utils/images";
import { FaUser } from "react-icons/fa";
import { motion } from "framer-motion";
import { baseUrl, removeFromCart } from "../../../../utils/apiData";
import axios from "axios";
import { useContext, useState } from "react";
import { webContext } from "../../../../webContext/WebContext";

const DeleteProductPopup = ({ setDeleteShow, selectedItem }) => {
  const { toastSuccess, toastError, setRemoveCartEffect } =
    useContext(webContext);
  const [loader, setLoader] = useState(false);
  // Remove from cart API

  const removeFromCartApi = async (itemId) => {
    setLoader(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${removeFromCart}`,
        { cartItemId: itemId },
        { headers }
      );

      if (res?.data?.success) {
        toastSuccess(res?.data?.message);
        setLoader(false);
        setDeleteShow(false);
        setRemoveCartEffect((prev) => prev + 1);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
    }
  };

  return (
    <div className="delet_item_pop">
      <motion.div
        initial={{ opacity: 0, scaleY: 0 }}
        whileInView={{ opacity: 1, scaleY: 1 }}
        transition={{ duration: 0.4, ease: "easeInOut" }}
        className="popup_card"
      >
        <div className="line"></div>
        <h4>Remove from cart?</h4>
        <div className="info_card">
          <div className="img_wrap">
            <img src={selectedItem?.course_id?.image} alt="course" />
          </div>
          <div className="content">
            <h5>{selectedItem?.course_id?.name}</h5>
            <div className="name_line">
              <FaUser />
              <p>{selectedItem?.course_id?.author_name}</p>
            </div>
            <div className="price_line">
              <h5>₹{selectedItem?.course_id?.price_inr}</h5>
              <div className="pill">
                <p>{selectedItem?.course_id?.modules_count} Modules</p>
              </div>
            </div>
          </div>
        </div>
        <div className="btns_wrap">
          <button
            type="button"
            className="secondary_btn bordered"
            onClick={() => setDeleteShow(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="secondary_btn"
            onClick={() => {
              removeFromCartApi(selectedItem?._id);
            }}
          >
            {loader ? "Loading..." : "Yes, Remove"}
          </button>
        </div>
      </motion.div>
    </div>
  );
};
export default DeleteProductPopup;
