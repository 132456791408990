
import ViewBlogLanding from "./viewBlogLanding/ViewBlogLanding";
import WebLayout from "../../components/weblayout/WebLayout";
import BlogInfo from "./blogInfo/BlogInfo";
import RelatedBlogs from "./relatedBlogs/RelatedBlogs";
import BlogCta from "./blogCta/BlogCta";
import { useParams } from "react-router-dom";
import PageLoader from "../../lms/lmsComponents/pageLoader/PageLoader";

import axios from "axios";
import { baseUrl, getBlogDetail, getBlog } from "../../utils/apiData";
import { Fragment, useEffect, useState } from "react";

const BlogDetail = () => {
  const [blogs, setBlogs] = useState([]);
  const [blog, setBlog] = useState({});
  const [loader, setLoader] = useState(false);

  const { id } = useParams();

  const getBlogDetailApi = async () => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${getBlogDetail}`, { slug: id });
      if (res?.data?.success) {
        setBlog(res?.data?.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };





  const getBlogApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${getBlog}`);
      if (res?.data?.success) {
        setLoader(false);
        setBlogs(res?.data?.data);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };



  useEffect(() => {
    getBlogDetailApi();
    getBlogApi();
  }, []);

  return (
    <WebLayout>
      {loader ? (
        <PageLoader />
      ) : (
        <Fragment>
          <ViewBlogLanding blog={blog} />
          <BlogInfo blog={blog} />
          <RelatedBlogs blogs={blogs} currentSlug={id} />
          <BlogCta />
        </Fragment>
      )}
    </WebLayout>
  );
};
export default BlogDetail;
