import { images } from "../../../utils/images";
import "./RemoveBookmarkModal.scss";
import Modal from "react-bootstrap/Modal";
import { FaUser } from "react-icons/fa";

import { addBookMark, baseUrl } from "../../../utils/apiData";
import axios from "axios";
import { useContext, useState } from "react";
import { webContext } from "../../../webContext/WebContext";

function RemoveBookmarkModal(props) {
  const [loader, setLoader] = useState(false);
  const { toastError, toastSuccess, setBookMarkEffect } = useContext(webContext);

  // BookmarkApi

  const courseBookmarkApi = async () => {
    setLoader(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${addBookMark}`,
        { course_id: `${props?.data?._id}` },
        { headers }
      );
      if (res?.data?.success) {
        setLoader(false);        
        setBookMarkEffect(prev => prev + 1);
        
        toastSuccess(res?.data?.message);
        props.onHide();
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="bookmark_remove_modal"
    >
      <Modal.Body>
        <div className="line"></div>
        <h4>
          {props?.data?.is_bookmarked
            ? "Remove from Favorites?"
            : "Add to Favorites?"}
        </h4>
        <div className="info_card">
          <div className="img_wrap">
            {
              props?.data?.image ? <img src={props?.data?.image} alt="course" /> : <img src={images.course} alt="course" />
            }

          </div>
          <div className="content">
            <h4>{props?.data?.name}</h4>
            <div className="name_line">
              <FaUser />
              <p>{props?.data?.author_name}</p>
            </div>
            <div className="price_line">
              <h5>&#x20B9; {props?.data?.price_inr}</h5>
              <div className="pill">
                <p>{props?.data?.modules_count} Modules</p>
              </div>
            </div>
          </div>
        </div>
        <div className="btns_wrap">
          <button
            type="button"
            className="secondary_btn bordered"
            onClick={props.onHide}
          >
            Cancel
          </button>
          <button
            type="button"
            className="secondary_btn"
            onClick={courseBookmarkApi}
          >
            {loader
              ? "Loading..."
              : `Yes, ${props?.data?.is_bookmarked ? "Remove" : "Add"}`}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default RemoveBookmarkModal;
