import "./Coaching.scss";
import WebLayout from "../../components/weblayout/WebLayout";
import CoachingLanding from "./coachingLanding/CoachingLanding";
import CTA from "../../components/cta/CTA";
import { useNavigate } from "react-router-dom";
import CoachingDetail from "./coachingDetail/CoachingDetail";

import { Fragment, useEffect, useState } from "react";
import PageLoader from "../../lms/lmsComponents/pageLoader/PageLoader";
import axios from "axios";
import { baseUrl, coachingPage } from "../../utils/apiData";

const Coaching = () => {
  const navigate = useNavigate();

  const [data, setdata] = useState({});
  const [loader, setLoader] = useState(false);

  const coachingPageApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${coachingPage}`);
      if (res?.data?.success) {
        setdata(res?.data?.data);
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    coachingPageApi();
  }, []);

  return (
    <WebLayout>
      {loader ? (
        <PageLoader />
      ) : (
        <Fragment>
          <CoachingLanding data={data} />
          <CoachingDetail data={data} />
          <CTA
            title="Ready for the Unprecedented?"
            btnText="Get in Touch"
            clickHandler={() => navigate(`/contact`)}
          />
        </Fragment>
      )}
    </WebLayout>
  );
};
export default Coaching;
