import { Fragment, useEffect, useState } from "react";
import "./CoursesList.scss";
import { images } from "../../../utils/images";
import CourseListCard from "./courseListCard/CourseListCard";
import axios from "axios";
import { baseUrl, offeringCourses } from "../../../utils/apiData";
import PageLoader from "../../../lms/lmsComponents/pageLoader/PageLoader";

const CoursesList = () => {
  const [tab, setTab] = useState(1);
  const [courses, setCourses] = useState([]);
  const [loader, setLoader] = useState(false);

  const getOfferingCoursesApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${offeringCourses}`);
      if (res?.data?.data) {
        setCourses(res?.data?.data);
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getOfferingCoursesApi();
  }, []);

  const tabType = ["courses", "upcoming"];

  return (

    <section className="course_list_sec p_b">
      <div className="content_wrap">

{
  loader ? <PageLoader /> :


        <Fragment>
        <div className="course_tab_wrap">
          <div className="tab_wraper_box">
            <button
              type="button"
              className={tab === 1 ? "active" : ""}
              onClick={() => setTab(1)}
            >
              Courses
            </button>
            <button
              type="button"
              className={tab === 2 ? "active" : ""}
              onClick={() => setTab(2)}
            >
              Upcoming
            </button>
          </div>
        </div>

        <div className="course_list_grid">
          {courses?.filter((ele) => ele?.category === tabType[tab - 1]).length >
          0 ? (
            courses
              ?.filter((ele) => ele?.category === tabType[tab - 1])
              ?.map((data) => {
                return (
                  <Fragment key={data._id}>
                    <CourseListCard data={data} />
                  </Fragment>
                );
              })
          ) : (
            <p>Data not found...</p>
          )}
        </div>
        </Fragment>
        }
      </div>
    </section>
  );
};

export default CoursesList;
