import LmsLayout from "../lmsComponents/lmsLayout/LmsLayout";
import "./LmsCourseDetail.scss";
import CourseDetailInfo from "./courseDetailInfo/CourseDetailInfo";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl, getCourseDetail } from "../../utils/apiData";
import { Fragment, useContext, useEffect, useState } from "react";
import { webContext } from "../../webContext/WebContext";
import PageLoader from "../lmsComponents/pageLoader/PageLoader";

const LmsCourseDetail = () => {

  const location = useLocation();
  

  const { toastError, addCartEffect, bookMarkEffect, removeCartEffect } =
    useContext(webContext);
  const [loader, setLoader] = useState(false);
  const { id } = useParams();
  const [courseDetail, setCourseDetail] = useState({});

  const getCourseDetailApi = async () => {
    setLoader(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${getCourseDetail}`,
        { course_id: id },
        { headers }
      );
      if (res?.data?.success) {
        setLoader(false);
        setCourseDetail(res?.data?.data);
      
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getCourseDetailApi(id);
  }, [id, addCartEffect, removeCartEffect, bookMarkEffect, location.pathname]);

  useEffect(() => {
    getCourseDetailApi(id);
  }, []);

  return (
    <LmsLayout>
      {loader ? (
        <PageLoader />
      ) : (
        <Fragment>
          <CourseDetailInfo courseDetail={courseDetail} id={id} />
        </Fragment>
      )}
    </LmsLayout>
  );
};

export default LmsCourseDetail;
