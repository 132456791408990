// export const baseUrl = " https://admin.inauditusintervention.com";
export const baseUrl = "https://inaudit.supagrow.in";

// For Web
export const homePage = "api/homepageapi";
export const aboutPage = "api/aboutpageapi";
export const invertionPage = "api/invertionpageapi";
export const coachingPage = "api/coachingpageapi";
export const consultancyPagee = "api/counsaltancypageapi";


export const lmsHomeCta = "api/homectaweb"
export const userRegistration = "api/userregister";
export const getOrgOptions = "api/getorganization";
export const userLogin = "api/userlogin";
export const offeringCourses = "api/getwebcourses";
export const offeringCourseDetail = "api/webcoursedetails ";
export const emailSubscription = "api/emailsubscribe";
export const getBlog = "api/getblogs";
export const getBlogDetail = "api/blogdetails";


// LMS
export const getHome = "api/gethomescreen";
export const getProfile = "api/getprofile";
export const updateProfile = "api/updateprofile";
export const updateProfileImage = "api/updateprofileimage";
export const updatePassword = "api/updatepassword";
export const logout = "api/logout";
export const deleteAccount = "api/deleteaccount";
export const getFaqs = "api/getfaq";
export const purchasedHistory = "api/purchasehistory";
export const contact = "api/storecontactapi";

export const getCourses = "api/getcourses";
export const getAllCourses = "api/getallcourses";
export const getCourseDetail = "api/coursedetails";
export const getModulesList = "api/modulelist";
export const getChapters = "api/getchapterbymodules";
export const bookmarkCategory = "api/getcategories";
export const getBookmarks = "api/getbookmarkcourses";
export const addBookMark = "api/addbookmarkcourses";
export const getnotification = "api/getnotification";
export const getCertificate = "api/getcertificate";
export const getCart = "api/getcart";
export const addToCart = "api/addtocart";
export const removeFromCart = "api/removecart";
export const removeCoupon = "api/removecoupon";
export const applyCoupon = "api/applycoupon";
export const helpCenter = "api/helpstore";

export const chapterComplate ="api/chaptercomplate";

export const chapterById = "api/chapterdetails";
export const submitAssignment = "api/submitassignment";

export const getQuiz = "api/getquizz";
export const submitQuiz = "api/submitquizz";
export const quizReport = "api/quizzreport";    
export const quizTime = "api/getquizztime";                         

//Forgot Password

export const forgotPassword = "api/forgotpassword";
export const verifyOtp = "api/verifyotp";
export const setNewPassword = "api/setnewpassword";

// Payment
export const createOrder = "api/createorder";
export const paymentSuccessHome = "api/paymentsuccesshome";


export const appDeleteAccount = "api/deleteaccountfrontend";


