import { useState, useRef, useContext, createContext } from "react";
import "./EditProfile.scss";
import { motion } from "framer-motion";
import ScreenTitle from "../screenTitle/ScreenTitle";
import axios from "axios";
import { baseUrl, updateProfile } from "../../../../../utils/apiData";
import { webContext } from "../../../../../webContext/WebContext";

const EditProfile = ({ setScreen, getProfileApi, currentProfile }) => {
  const [loader, setLoader] = useState(false);
  const { toastError, toastSuccess } = useContext(webContext);

  const [nameError, setNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [addressError, setAddressError] = useState("");

  const [profileData, setProfileData] = useState({
    name: currentProfile?.name || "",
    mobile: currentProfile?.mobile || "",
    address: currentProfile?.address || "",
  });

  const fieldChanger = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => {
      return { ...prev, [name]: value };
    });

    validations(name, value);
  };

  const validations = (name, value) => {
    if (name === "name") {
      if (value.trim().length == 0) {
        setNameError("Field is required!");
      } else {
        setNameError("");
      }
    }

    if (name === "number") {
      if (value.trim().length == 0) {
        setMobileError("Field is required!");
      } else {
        setMobileError("");
      }
    }

    if (name === "address") {
      if (value.trim().length == 0) {
        setAddressError("Field is required!");
      } else {
        setAddressError("");
      }
    }
  };

  const blurHandler = (e) => {
    const { name, value } = e.target;
    validations(name, value);
  };







  // Profile Update Api
  const profileUpdateApi = async () => {
    setLoader(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const res = await axios.post(
        `${baseUrl}/${updateProfile}`,
        {
          ...profileData,
        },
        { headers }
      );
      if (res?.data?.success) {
        setLoader(false);
        toastSuccess(res?.data?.message || "Profile Updated!");
        getProfileApi();
        setScreen(0);
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
      toastError(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const profileUpdateSubmit = (e) => {
    if (profileData.name.trim().length === 0) {
      setNameError("Field is required!");
    }

    if (profileData.mobile.trim().length === 0) {
      setMobileError("Field is required!");
    }

    if (profileData.address.trim().length === 0) {
      setAddressError("Field is required!");
    }
    e.preventDefault();

    if (nameError || mobileError || addressError) {
    } else {
      profileUpdateApi();
      setProfileData({ name: "", mobile: "", address: "" });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, scaleY: 0.4 }}
      whileInView={{ opacity: 1, scaleY: 1 }}
      transition={{ duration: 0.4, ease: "easeInOut" }}
      className="edit_profile"
    >
      <ScreenTitle title="Edit Profile" clickHandler={() => setScreen(0)} />

      <form>
       
        <div className="input_wrap">
          <input
            type="text"
            placeholder="Full name"
            name="name"
            value={profileData.name}
            onChange={fieldChanger}
            onBlur={blurHandler}
          />
          {nameError && <p className="error">{nameError}</p>}
        </div>

        <div className="input_wrap">
          <input
            type="number"
            placeholder="Phone number"
            name="mobile"
            value={profileData.mobile}
            onChange={fieldChanger}
            onBlur={blurHandler}
          />
          {mobileError && <p className="error">{mobileError}</p>}
        </div>

        <div className="input_wrap">
          <input
            type="text"
            placeholder="Address"
            name="address"
            value={profileData.address}
            onChange={fieldChanger}
            onBlur={blurHandler}
          />
          {addressError && <p className="error">{addressError}</p>}
        </div>
        {loader ? (
          <button type="button" className="secondary_btn">
            Loading...
          </button>
        ) : (
          <button
            type="button"
            className="secondary_btn"
            onClick={profileUpdateSubmit}
          >
            Update
          </button>
        )}
      </form>
    </motion.div>
  );
};

export default EditProfile;
