import "./ChangePassword.scss";
import { IoChevronBackSharp } from "react-icons/io5";
import { IoEyeSharp } from "react-icons/io5";
import { FaEyeSlash } from "react-icons/fa";
import { useContext, useState } from "react";
import { motion } from "framer-motion";
import ScreenTitle from "../screenTitle/ScreenTitle";
import axios from "axios";
import { baseUrl, updatePassword } from "../../../../../utils/apiData";
import { webContext } from "../../../../../webContext/WebContext";

const ChangePassword = ({ setScreen }) => {
  const { toastSuccess, toastError } = useContext(webContext);

  const [success, setSuccess] = useState(false);
  const [loader, setLoader] = useState();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [oldEye, setOldEye] = useState(false);
  const [newEye, setNewEye] = useState(false);
  const [confirmEye, setConfirmEye] = useState(false);

  const [oldError, setOldError] = useState("");
  const [newError, setNewError] = useState("");
  const [confirmError, setConfirmError] = useState("");

  const passwordChangeApi = async () => {
    setLoader(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${updatePassword}`,
        {
          currentPassword: oldPassword,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        },
        { headers }
      );
      if (res?.data?.success) {
        setLoader(false);
        setSuccess(true);
        toastSuccess(res?.data?.message || "Password update Sucess!");
        setTimeout(() => {
          setScreen(0);
        }, 2500);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
    }
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const updateHandler = (e) => {
    e.preventDefault();
    if (oldPassword.trim().length === 0) {
      setOldError("Field is required!");
    } else if (newPassword.trim().length === 0) {
      setOldError("Field is required!");
    } else if (confirmPassword.trim().length === 0) {
      setConfirmError("Field is required!");
    } else if (newPassword != confirmPassword) {
      setConfirmError("Password does not match!");
    } else if (
      oldPassword.trim().length > 0 &&
      newPassword.trim().length > 0 &&
      confirmPassword.trim().length > 0 &&
      newPassword == confirmPassword
    ) {
      passwordChangeApi();
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, scaleY: 0.4 }}
      whileInView={{ opacity: 1, scaleY: 1 }}
      transition={{ duration: 0.4, ease: "easeInOut" }}
      className="change_password"
    >
      <ScreenTitle title="Update Password" clickHandler={() => setScreen(0)} />

      {success ? (
        <motion.div
          initial={{ opacity: 0, scaleY: 0.4 }}
          whileInView={{ opacity: 1, scaleY: 1 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
          className="success"
        >
          <h4>Success!</h4>
          <p>Password Updated!</p>
        </motion.div>
      ) : (
        <form>
          <div className="password_wrap">
            <button
              type="button"
              className="eye"
              onClick={() => setOldEye((prev) => !prev)}
            >
              {oldEye ? <FaEyeSlash /> : <IoEyeSharp />}
            </button>
            <input
              type={oldEye ? "text" : "password"}
              placeholder="Old Password"
              value={oldPassword}
              onChange={(e) => {
                setOldPassword(e.target.value);
                if (e.target.value.trim().length === 0) {
                  setOldError("Field is required!");
                } else {
                  setOldError("");
                }
              }}
              onBlur={(e) => {
                if (e.target.value.trim().length === 0) {
                  setOldError("Field is required!");
                } else {
                  setOldError("");
                }
              }}
            />
          </div>
          {oldError && <p className="error">{oldError}</p>}

          <div className="password_wrap">
            <button
              type="button"
              className="eye"
              onClick={() => setNewEye((prev) => !prev)}
            >
              {newEye ? <FaEyeSlash /> : <IoEyeSharp />}
            </button>
            <input
              type={newEye ? "text" : "password"}
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                if (e.target.value.trim().length === 0) {
                  setNewError("Field is required!");
                } else {
                  setNewError("");
                }
              }}
              onBlur={(e) => {
                if (e.target.value.trim().length === 0) {
                  setNewError("Field is required!");
                } else {
                  setNewError("");
                }
              }}
            />
          </div>
          {newError && <p className="error">{newError}</p>}

          <div className="password_wrap">
            <button
              type="button"
              className="eye"
              onClick={() => setConfirmEye((prev) => !prev)}
            >
              {confirmEye ? <FaEyeSlash /> : <IoEyeSharp />}
            </button>
            <input
              type={confirmEye ? "text" : "password"}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                if (e.target.value.trim().length === 0) {
                  setConfirmError("Field is required!");
                } else {
                  setConfirmError("");
                }
              }}
              onBlur={(e) => {
                if (e.target.value.trim().length === 0) {
                  setConfirmError("Field is required!");
                } else if (newPassword !== e.target.value.trim()) {
                  setConfirmError("Password does not match!");
                } else {
                  setConfirmError("");
                }
              }}
            />
          </div>
          {confirmError && <p className="error">{confirmError}</p>}
          {loader ? (
            <button type="button" className="secondary_btn">
              Loading...
            </button>
          ) : (
            <button
              type="button"
              className="secondary_btn"
              onClick={updateHandler}
            >
              Update
            </button>
          )}
        </form>
      )}
    </motion.div>
  );
};
export default ChangePassword;
