import { useNavigate } from "react-router-dom";
import CTA from "../../components/cta/CTA";
import WebLayout from "../../components/weblayout/WebLayout";
import AboutLanding from "./aboutLanding/AboutLanding";
import AboutPillSlider from "./aboutPillSlider/AboutPillSlider";
import Impact from "./impact/Impact";
import OurVision from "./ourVision/OurVision";
import Team from "./team/Team";

import PageLoader from "../../lms/lmsComponents/pageLoader/PageLoader";

import axios from "axios";
import { baseUrl, aboutPage } from "../../utils/apiData";
import { Fragment, useEffect, useState } from "react";

const About = () => {

  const navigate = useNavigate();

  const [about, setAbout] = useState({});
  const [loader, setLoader] = useState(false);

  const aboutPageApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${aboutPage}`);
      if (res?.data?.success) {
        setAbout(res?.data?.data);
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    aboutPageApi();
  }, []);

  return (
    <WebLayout>
      {loader ? (
        <PageLoader />
      ) : (
        <Fragment>
          <AboutLanding />
          <AboutPillSlider about={about} />
          <OurVision about={about} />
          <Impact about={about} />
          <Team about={about} />
          <CTA
            title="Ready for the Unprecedented?"
            btnText="Get in Touch"
            clickHandler={() => navigate(`/contact`)}
          />
        </Fragment>
      )}
    </WebLayout>
  );
};
export default About;
