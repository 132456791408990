import { useNavigate } from "react-router-dom";
import "./DeleteAccountPop.scss";
import { motion } from "framer-motion";

import axios from "axios";
import { baseUrl, deleteAccount } from "../../../../../utils/apiData";
import { useContext, useEffect, useState } from "react";
import { webContext } from "../../../../../webContext/WebContext";

const DeleteAccountPop = ({ setScreen, reason }) => {


  const navigate = useNavigate();

  const { toastSuccess, toastError } = useContext(webContext);


  const [loader, setLoader] = useState(false);




  const deleteAccountApi = async () => {
    setLoader(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${deleteAccount}`,
        { reason },
        { headers }
      );
      if (res?.data?.success) {
        setLoader(false);
        toastSuccess(res?.data?.message || "Deleted");
        navigate("/signup");
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
      navigate("/signup");
    }
  };



  return (
    <div className="delete_account">
      <motion.div
        initial={{ opacity: 0, scaleY: 0.4 }}
        whileInView={{ opacity: 1, scaleY: 1 }}
        transition={{ duration: 0.4, ease: "easeInOut" }}
        className="logout_modal"
      >
        <div className="line"></div>
        <h4>Are you sure you want to delete your account?</h4>

        <button
          type="button"
          className="secondary_btn"
          onClick={deleteAccountApi}
        >
          {loader ? "Loading..." : "Yes" }
        </button>
        <button
          type="button"
          className="secondary_btn bordered"
          onClick={() => setScreen(0)}
        >
          Cancel
        </button>
      </motion.div>
    </div>
  );
};
export default DeleteAccountPop;
