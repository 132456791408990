import "./HomeRecommended.scss";
import { Fragment, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { motion } from "framer-motion";
import axios from "axios";
import { baseUrl, addToCart } from "../../../utils/apiData";

import CourseCard from "../../lmsComponents/courseCard/CourseCard";
import { useContext } from "react";
import { webContext } from "../../../webContext/WebContext";
import RemoveBookmarkModal from "../../lmsBookmark/removeBookmarkModal/RemoveBookmarkModal";
import { useNavigate } from "react-router-dom";

const HomeRecommended = ({ courses }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState();
  const [loader, setLoader] = useState(false);

  const { toastError, toastSuccess, setAddCartEffect } = useContext(webContext);

  // Add To Cart API
  const addToCartApi = async (courseId) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${addToCart}`,
        { course_id: courseId },
        { headers }
      );

      if (res?.data?.success) {
        toastSuccess(res?.data?.message);
        setAddCartEffect((prev) => prev + 1);
      }
    } catch (error) {
  
      console.error(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const bookmarkHandler = (e, data) => {
    e.stopPropagation();
    setSelectedCourse(data);
    setShow(true);
  };

  const addToCartHandler = (e, data) => {
    e.stopPropagation();
    addToCartApi(data?._id);
  };

  return (
    <Fragment>
      <section className="recommended_sec p_t">
        <div className="content_wrap">
          <motion.h3
            initial={{ translateY: 48, opacity: 0 }}
            whileInView={{ translateY: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >
            All Courses
          </motion.h3>

          {courses?.length > 0 ? (
            <div className="course_slider">
              <Swiper
                spaceBetween={32}
                slidesPerView={3}
                breakpoints={{
                  950: {
                    slidesPerView: 3,
                    spaceBetween: 32,
                  },
                  650: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                  },
                  100: {
                    slidesPerView: 1,
                    spaceBetween: 16,
                  },
                }}
              >
                {courses.map((data, j) => {
                  return (
                    <SwiperSlide key={data._id}>
                      <CourseCard
                        test={data?.is_cart}
                        {...data}
                        index={j}
                        cartBtn={!data?.is_cart}
                        bookmark={true}
                        ongoing={false}
                        filledBookmark={data?.is_bookmarked}
                        bookmarkHandler={(e) => bookmarkHandler(e, data)}
                        addCartHandler={(e) => addToCartHandler(e, data)}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          ) : (
            <p className="text-center mt-5">Recommended not found... </p>
          )}

          <div className="view_more">
            <button type="button" className="secondary_btn" onClick={() => navigate("/auth/allcourses")}>View More</button>
          </div>
        </div>
      </section>
      <RemoveBookmarkModal
        show={show}
        data={selectedCourse}
        onHide={() => setShow(false)}
        // recallApi={getCoursesApi}
      />
    </Fragment>
  );
};

export default HomeRecommended;
