import "./Certificate.scss";
import { baseUrl, getCertificate } from "../../../../utils/apiData";
import axios from "axios";
import { useEffect } from "react";

const Certificate = () => {
  const getCertificateApi = async () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.get(`${baseUrl}/${getCertificate}`, { headers });
   
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCertificateApi();
  }, []);

  return (
    <section className="certificate">
      <div className="content_wrap">
        <div className="skeleton">
          <h4>Complete the course for a certificate</h4>
          <button className="secondary_btn" disabled>
            Get Certificate
          </button>
        </div>
      </div>
    </section>
  );
};

export default Certificate;
