import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";
import { FaGoogle } from "react-icons/fa";

 const GoogleLoginBtn = ({signInApi}) => {
  return (
    <button type="button" className="google">
      <div className="overlay">
        <FaGoogle />
      </div>
      <GoogleLogin
        shape="pill"
        width="350px"
        onSuccess={(credentialResponse) => {
          const credRes = jwtDecode(credentialResponse.credential);

          signInApi({
            email: credRes?.email,
            logintype: "social",
            method: "google",
            social_id: credRes?.sub,
          });
        }}
        onError={() => {
          console.error("Login Failed");
        }}
      />
    </button>
  );
};


export default GoogleLoginBtn;