import ScreenTitle from "../screenTitle/ScreenTitle";
import "./Faq.scss";
import { GoSearch } from "react-icons/go";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { webContext } from "../../../../../webContext/WebContext";
import { baseUrl, getFaqs } from "../../../../../utils/apiData";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

const Faq = ({ setScreen }) => {
  const navigate = useNavigate();

  const { toastError } = useContext(webContext);
  const [loader, setLoader] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [faq, setFaq] = useState([]);

  const searchHandler = (e) => {
    setKeyword(e.target.value);
  };

  const getFaqApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${getFaqs}?query=${keyword}`);
      if (res?.data?.success) {
        setFaq(res?.data?.data);
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
      toastError(error?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      getFaqApi();
    }, 600);

    return () => clearTimeout(delayInputTimeoutId);
  }, [keyword]);

  return (
    <motion.div
      initial={{ opacity: 0, scaleY: 0.4 }}
      whileInView={{ opacity: 1, scaleY: 1 }}
      transition={{ duration: 0.4, ease: "easeInOut" }}
      className="faq"
    >
      <ScreenTitle title="FAQ" clickHandler={() => setScreen(0)} />
     
      <div className="input_wraper">
        <GoSearch />
        <input
          type="text"
          placeholder="Search"
          value={keyword}
          onChange={searchHandler}
        />
      </div>
      <h6>Frequently Asked</h6>
      <div className="faq_list">
        {loader ? (
          <div className="loader_card">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        ) : faq?.length > 0 ? (
          faq.map((data) => {
            return (
              <div
                className="faq_card"
                key={data._id}
                onClick={() => navigate("/auth/faq")}
              >
                <p>{data.name}</p>
                <p className="light">{data.answer}</p>
              </div>
            );
          })
        ) : (
          <p>Data not found...</p>
        )}
      </div>
    </motion.div>
  );
};

export default Faq;
