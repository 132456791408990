import {
  Home,
  About,
  Blogs,
  BlogDetail,
  CourseDetail,
  SignUp,
  SignIn,
  ForgotPassword,
  OtpVerify,
  ProtectedRoute,
  LmsHome,
  LmsCourse,
  LmsCourseDetail,
  LmsFaq,
  LmsBookmark,
  LmsLessons,
  LmsQuize,
  PaymentSuccess,
  PaymentFail,
  ChapterDetails,
  Offerings,
  Courses,
  Intervention,
  Coaching,
  Consultancy,
  Contact,
  AllCourses,
  LinkedInVerificationPage,
  OurTeam,
  PrivacyPolicy,
  DeleteAccount,
} from "../pages/index";

export const routeingData = [
  {
    id: 0,
    to: "/",
    ele: <Home />,
  },
  {
    id: 1,
    to: "/about",
    ele: <About />,
  },
  {
    id: 11,
    to: "/ourteam/:id",
    ele: <OurTeam />,
  },
  {
    id: 2,
    to: "/blogs",
    ele: <Blogs />,
  },
  {
    id: 3,
    to: "/blogs/:id",
    ele: <BlogDetail />,
  },
  {
    id: "contact",
    to: "/contact",
    ele: <Contact />,
  },
  {
    id: 5,
    to: "/offerings/courses/:slug",
    ele: <CourseDetail />,
  },
  {
    id: "offerings",
    to: "/offerings",
    ele: <Offerings />,
  },
  {
    id: "intervation",
    to: "/offerings/intervention",
    ele: <Intervention />,
  },
  {
    id: "coaching",
    to: "/offerings/coaching",
    ele: <Coaching />,
  },
  {
    id: "consultancy",
    to: "/offerings/consultancy",
    ele: <Consultancy />,
  },
  {
    id: "courses",
    to: "/offerings/courses",
    ele: <Courses />,
  },
  {
    id: 6,
    to: "/signup",
    ele: <SignUp />,
  },
  {
    id: 7,
    to: "/signin",
    ele: <SignIn />,
  },
  {
    id: 8,
    to: "/forgotpassword",
    ele: <ForgotPassword />,
  },
  {
    id: 8888,
    to: "/privacy_policy",
    ele: <PrivacyPolicy />,
  },
  {
    id: 9,
    to: "/verifyotp",
    ele: <OtpVerify />,
  },
  {
    id: 1000,
    to: "/delete-account",
    ele: <DeleteAccount />,
  },

  // LMS

  {
    id: "linkedin",
    to: "/auth/linkedin/callback",
    ele: <LinkedInVerificationPage />,
  },

  {
    id: 9,
    to: "/auth/home",
    ele: (
      <ProtectedRoute>
        <LmsHome />
      </ProtectedRoute>
    ),
  },
  {
    id: 999,
    to: "/auth/allcourses",
    ele: (
      <ProtectedRoute>
        <AllCourses />
      </ProtectedRoute>
    ),
  },
  {
    id: 99999,
    to: "/auth/allcourses/:category",
    ele: (
      <ProtectedRoute>
        <AllCourses />
      </ProtectedRoute>
    ),
  },
  {
    id: 10,
    to: "/auth/courses",
    ele: (
      <ProtectedRoute>
        <LmsCourse />
      </ProtectedRoute>
    ),
  },
  {
    id: 1000,
    to: "/auth/courses/:category",
    ele: (
      <ProtectedRoute>
        <LmsCourse />
      </ProtectedRoute>
    ),
  },
  {
    id: 11,
    to: "/auth/faq",
    ele: (
      <ProtectedRoute>
        <LmsFaq />
      </ProtectedRoute>
    ),
  },
  {
    id: 12,
    to: "/auth/bookmark",
    ele: (
      <ProtectedRoute>
        <LmsBookmark />
      </ProtectedRoute>
    ),
  },
  {
    id: 13,
    to: "/auth/quiz/:id",
    ele: (
      <ProtectedRoute>
        <LmsQuize />
      </ProtectedRoute>
    ),
  },
  {
    id: 14,
    to: "/auth/course/:id",
    ele: (
      <ProtectedRoute>
        <LmsCourseDetail />
      </ProtectedRoute>
    ),
  },
  {
    id: 14,
    to: "/auth/lessons/:id",
    ele: (
      <ProtectedRoute>
        <LmsLessons />
      </ProtectedRoute>
    ),
  },
  {
    id: 15,
    to: "/auth/chapter/:id",
    ele: (
      <ProtectedRoute>
        <ChapterDetails />
      </ProtectedRoute>
    ),
  },

  {
    id: 15,
    to: "/auth/chapter/:id",
    ele: (
      <ProtectedRoute>
        <ChapterDetails />
      </ProtectedRoute>
    ),
  },

  {
    id: 16,
    to: "/auth/paymentsuccess",
    ele: (
      <ProtectedRoute>
        <PaymentSuccess />
      </ProtectedRoute>
    ),
  },
  {
    id: 17,
    to: "/auth/paymentfail",
    ele: (
      <ProtectedRoute>
        <PaymentFail />
      </ProtectedRoute>
    ),
  },
];
