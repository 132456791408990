import "./CourseOverview.scss";
import { images } from "../../../../utils/images";
import NormalButton from "../../../../components/normalButton/NormalButton";
import { motion } from "framer-motion";
import { IoCartOutline } from "react-icons/io5";
import axios from "axios";
import { baseUrl, addToCart } from "../../../../utils/apiData";
import { Fragment, useContext } from "react";
import { webContext } from "../../../../webContext/WebContext";
import PageLoader from "../../../lmsComponents/pageLoader/PageLoader";

const CourseOverview = ({ courseDetail }) => {
  const { toastSuccess, toastError, setAddCartEffect } = useContext(webContext);

  // Add To Cart API
  const addToCartApi = async (courseId) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${addToCart}`,
        { course_id: courseId },
        { headers }
      );

      if (res?.data?.success) {
        toastSuccess(res?.data?.message);
        setAddCartEffect((prev) => prev + 1);
      }
    } catch (error) {
      console.error(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
    }
  };

  return (
    <Fragment>
      {courseDetail?._id ? (
        <section className="course_overview">
          <div className="content_wrap">
            <motion.h3
              initial={{ opacity: 0, translateY: 48 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              About the Course
            </motion.h3>
            <motion.p
              initial={{ opacity: 0, translateY: 48 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
            >
              {courseDetail?.course_description || "-"}
            </motion.p>
            {/* Profile */}
            <motion.div
              initial={{ opacity: 0, translateY: 48 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              className="profile_wrap"
            >
              <div className="img_wrap">
                {courseDetail?.author_image ? (
                  <img
                    src={`${courseDetail?.author_image}`}
                    alt="profile"
                  />
                ) : (
                  <img src={images.profileAvtar} alt="profile" />
                )}
              </div>
              <div className="text">
                <h5>{courseDetail?.author_name || "-"}</h5>
              </div>
            </motion.div>
            {/* Counting Info */}
            <div className="count_info">
              {/* <motion.div
                initial={{ opacity: 0, translateY: 48 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
                className="info"
              >
                <p>Students</p>
                <h5>{courseDetail?.total_students ?? "-"}</h5>
              </motion.div> */}

              <motion.div
                initial={{ opacity: 0, translateY: 48 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 0.6, ease: "easeInOut", delay: 0.1 }}
                className="info"
              >
                <p>Level </p>
                <h5>{courseDetail?.level || "-"}</h5>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, translateY: 48 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 0.6, ease: "easeInOut", delay: 0.3 }}
                className="info"
              >
                <p>Language </p>
                <h5>{courseDetail?.language || "-"}</h5>
              </motion.div>
            </div>

            <div className="bi_grid">
              <div className="info_box">
                <motion.h3
                  initial={{ opacity: 0, translateY: 48 }}
                  whileInView={{ opacity: 1, translateY: 0 }}
                  transition={{ duration: 0.6, ease: "easeInOut" }}
                >
                  Includes
                </motion.h3>
                <motion.div
                  initial={{ opacity: 0, translateY: 48 }}
                  whileInView={{ opacity: 1, translateY: 0 }}
                  transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
                  className="flex_box"
                >
                  {courseDetail?.course_include?.length > 0 ? (
                    courseDetail?.course_include?.map((item) => {
                      return (
                        <div className="square" key={item}>
                          <p>{item}</p>
                        </div>
                      )
                    })
                  ) : (
                    <p>No course included...</p>
                  )}
                </motion.div>
              </div>

              <div className="info_box">
                <motion.h3
                  initial={{ opacity: 0, translateY: 48 }}
                  whileInView={{ opacity: 1, translateY: 0 }}
                  transition={{ duration: 0.6, ease: "easeInOut" }}
                >
                  Skills
                </motion.h3>
                <motion.div
                  initial={{ opacity: 0, translateY: 48 }}
                  whileInView={{ opacity: 1, translateY: 0 }}
                  transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
                  className="flex_box"
                >
                  {courseDetail?.skills?.length > 0 ? (
                    courseDetail?.skills?.map((item, j) => {
                      return (
                        <div className="square" key={item?._id}>
                          <p>{item?.name}</p>
                        </div>
                      )
                    })
                  ) : (
                    <p>No Skill included...</p>
                  )}
                </motion.div>
              </div>
            </div>

            {/* {!courseDetail?.isInCart && (
              <div className="bottom_btn">
                <motion.div
                  initial={{ opacity: 0, translateY: 48 }}
                  whileInView={{ opacity: 1, translateY: 0 }}
                  transition={{ duration: 0.6, ease: "easeInOut" }}
                  className="text"
                >
                  <p>Total</p>
                  <h2>₹{courseDetail?.price_inr ?? "-"}</h2>
                </motion.div>
                <NormalButton
                  title="Add to cart"
                  type="filled"
                  clickHandler={() => addToCartApi(courseDetail?._id)}
                  icon={<IoCartOutline />}
                />
              </div>
            )} */}


          </div>
        </section>
      ) : (
        <PageLoader />
      )}
    </Fragment>
  );
};

export default CourseOverview;
