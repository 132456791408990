import WebLayout from "../../components/weblayout/WebLayout";
import CourseLanding from "./courseLanding/CourseLanding";
import CourseCounter from "./courseCounter/CourseCounter";
import Quote from "./quote/Quote";
import CourseAcc from "./courseAcc/CourseAcc";
import CourseGrid from "./courseGrid/CourseGrid";
import CourseCta from "./courseCTA/CourseCta";
import Needs from "./needs/Needs";

import { useParams } from "react-router-dom";
import { baseUrl, offeringCourseDetail } from "../../utils/apiData";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";

import PageLoader from "../../lms/lmsComponents/pageLoader/PageLoader";

const CourseDetail = () => {
  const [detail, setDetail] = useState({});
  const [loader, setLoader] = useState(false);

  const { slug } = useParams();

  const getCourseDetailApi = async () => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${offeringCourseDetail}`, {
        slug: `${slug}`,
      });
      if (res?.data?.success) {
        setDetail(res?.data?.data);
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getCourseDetailApi();
  }, []);

  return (
    <WebLayout>
      {loader ? (
        <PageLoader />
      ) : (
        <Fragment>
          <CourseLanding
            name={detail?.name}
            p1={detail?.subtitle}
            img={detail?.image}
          />
          <CourseCounter detail={detail} />

          {detail?.testimonials?.length > 0 && (
            <Quote data={detail?.testimonials[0]} />
          )}

          {detail?.faqs > 0 && <CourseAcc data={detail?.faqs} />}

          {detail?.benefits?.length > 0 && (
            <CourseGrid data={detail?.benefits} />
          )}

          {detail?.univercity_needs && (
            <Needs data={detail?.univercity_needs} />
          )}

          <CourseCta />
        </Fragment>
      )}
    </WebLayout>
  );
};
export default CourseDetail;
