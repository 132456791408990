import { useContext, useRef, useState } from "react";
import "./ProfilePhoto.scss";
import { images } from "../../../../../utils/images";
import ScreenTitle from "../screenTitle/ScreenTitle";
import axios from "axios";
import { baseUrl, updateProfileImage } from "../../../../../utils/apiData";
import { webContext } from "../../../../../webContext/WebContext";
import { motion } from "framer-motion";

const ProfilePhoto = ({ setScreen, currentProfile, getProfileApi }) => {
  const [newProfile, setNewProfile] = useState(false);
  const { toastSuccess, toastError } = useContext(webContext);
  // URL.createObjectURL
  const [loader, setLoader] = useState(false);

  const [profile, setProfile] = useState(currentProfile?.profile || "");

  const inputRef = useRef(null);

  const handleBoxClick = () => {
    inputRef.current.click();
  };
  const handleImageChange = (e) => {
    setProfile(e.target.files[0]);
    setNewProfile(true);
  };

  const updateProfileApi = async (e) => {
    e.preventDefault();
    setLoader(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${updateProfileImage}`,
        { profile: profile },
        { headers }
      );
      if (res?.data?.success) {
        setLoader(false);
        toastSuccess(res?.data?.message);
        getProfileApi();
        setScreen(0);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
    }
  };

  return (
    <motion.div 
    initial={{ opacity: 0, scaleY: 0.4 }}
    whileInView={{ opacity: 1, scaleY: 1 }}
    transition={{ duration: 0.4, ease: "easeInOut" }}
    className="update_profile_photo">
      <ScreenTitle title="Update Profile" clickHandler={() => setScreen(0)} />
      <form encType="multipart/form-data">
        <div className="profile_wrap" onClick={handleBoxClick}>
          {newProfile ? (
            <img src={URL.createObjectURL(profile)} alt="profile" />
          ) : profile ? (
            <img src={profile} alt="profile" />
          ) : (
            <img src={images.profileAvtar} alt="profile" />
          )}
          <input
            type="file"
            ref={inputRef}
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
        </div>
        {loader ? (
          <button type="button" className="secondary_btn">
            Loading...
          </button>
        ) : (
          <button
            type="button"
            className="secondary_btn"
            onClick={updateProfileApi}
          >
            Update
          </button>
        )}
      </form>
    </motion.div>
  );
};

export default ProfilePhoto;
