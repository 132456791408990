import "./BlogInfo.scss";
import { FaPaperPlane } from "react-icons/fa6";
import NormalButton from "../../../components/normalButton/NormalButton";
import {motion} from "framer-motion";
import { BsTwitterX } from "react-icons/bs";
import { useContext, useState } from "react";
import { webContext } from "../../../webContext/WebContext";
import axios from "axios";
import { baseUrl, emailSubscription } from "../../../utils/apiData";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";


const BlogInfo = ({ blog }) => {
  const [emailError, setEmailError] = useState("");
  const [mail, setMail] = useState("");
  const [loader, setLoader] = useState("");

  const { toastSuccess, toastError } = useContext(webContext);

  const emailSubscribeApi = async () => {
    const Emailregwx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (mail === 0) {
      setEmailError("Field is required");
    } else if (!Emailregwx.test(mail)) {
      setEmailError("Invalid Email!");
    } else {
      setLoader(true);
      try {
        const res = await axios.post(`${baseUrl}/${emailSubscription}`, {
          email: mail,
        });

        if (res?.data?.success) {
          toastSuccess(res?.data?.message);
          setMail("");
          setEmailError("");
          setLoader(false);
        }
      } catch (error) {
        console.error(error);
        toastError(error?.response?.data?.message || "Something went wrong!");
        setLoader(false);
      }
    }
  };

  const inputHandler = (e) => {
    const Emailregwx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (e.target.value.length === 0) {
      setEmailError("Field is required");
    } else if (!Emailregwx.test(e.target.value)) {
      setEmailError("Invalid Email!");
    } else {
      setEmailError("");
    }

    setMail(e.target.value);
  };

  const focusHandler = (e) => {
    setEmailError("");
  };

  const blurHandler = (e) => {
    if (e.target.value.length === 0) {
      setEmailError("Field is required");
    } else {
      setEmailError("");
    }
  };

  return (
    <section className="blog_info">
      <div className="content_wrap p_t p_b">
        <div className="info_divider">
          <div className="inbox_wrap">
            <div className="inbox_card">
              <div className="icon_box">
                <FaPaperPlane />
              </div>
              <h4>Let’s take this to your inbox</h4>
              <p>
                Subscribe for updates on our offerings, client success stories,
                and the latest insights.
              </p>
              <input
                type="email"
                placeholder="Email Address"
                value={mail}
                onChange={inputHandler}
                onFocus={focusHandler}
                onBlur={blurHandler}
              />
              {emailError && <p className="error">{emailError}</p>}
              <NormalButton
                title={loader ? "Loader..." : "I'm In"}
                type="bordered"
                clickHandler={emailSubscribeApi}
              />
            </div>
            <div className="social_list">
              <motion.a
                initial={{ opacity: 0, translateX: 50 }}
                whileInView={{ opacity: 1, translateX: 0 }}
                transition={{ duration: 0.6, ease: "easeInOut", delay: 0.3 }}
                href="https://in.linkedin.com/company/inauditus-intervention"
                target="_blank"
              >
                <FaLinkedinIn />
              </motion.a>
              <motion.a
                initial={{ opacity: 0, translateX: 50 }}
                whileInView={{ opacity: 1, translateX: 0 }}
                transition={{ duration: 0.6, ease: "easeInOut", delay: 0.4 }}
                href="https://x.com/InauditusI"
                target="_blank"
              >
                <BsTwitterX />
              </motion.a>
              <motion.a
                initial={{ opacity: 0, translateX: 50 }}
                whileInView={{ opacity: 1, translateX: 0 }}
                transition={{ duration: 0.6, ease: "easeInOut", delay: 0.5 }}
                href="https://www.youtube.com/channel/UCRlFa79ptOh83EgygGrY5IA"
                target="_blank"
              >
                <FaYoutube />
              </motion.a>
            </div>
          </div>
          <div
            className="info_wrap"
            dangerouslySetInnerHTML={{ __html: blog?.content }}
          ></div>
        </div>
      </div>
    </section>
  );
};

export default BlogInfo;
