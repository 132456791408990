import "./CourseList.scss";
import { Fragment, useContext, useEffect, useState } from "react";
import CourseCard from "../../lmsComponents/courseCard/CourseCard";
import PageLoader from "../../lmsComponents/pageLoader/PageLoader";
import axios from "axios";
import {
  baseUrl,
  getCourses,
  addToCart,
  bookmarkCategory,
} from "../../../utils/apiData";
import { webContext } from "../../../webContext/WebContext";
import RemoveBookmarkModal from "../../lmsBookmark/removeBookmarkModal/RemoveBookmarkModal";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const CourseList = () => {
  const { category } = useParams();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [catLoader, setCatLoader] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const {
    toastError,
    toastSuccess,
    addCartEffect,
    removeCartEffect,
    setAddCartEffect,
    logOutApi,
    bookMarkEffect,
    setAsModule,
  } = useContext(webContext);

  const [selectedCourse, setSelectedCourse] = useState();

  const [courses, setCourses] = useState();
  const [loader, setLoader] = useState(false);

  // Categories Api
  const getCategoriesApi = async () => {
    setCatLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${bookmarkCategory}`);
      if (res?.data?.success) {
        setCatLoader(false);
        setCategoryList(res?.data?.data);
      }
    } catch (error) {
      setCatLoader(false);
     console.error(error);
      if (error?.response?.status == 401) {
        logOutApi();
      }
    }
  };

  // Get Course List API
  const getCoursesApi = async () => {
    setLoader(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const res = await axios.post(
        `${baseUrl}/${getCourses}`,
        { categoryId: category || "" },
        { headers }
      );
      if (res?.data?.success) {
        setLoader(false);
        setCourses(res?.data?.data);
      }
    } catch (error) {
      setLoader(false);
     console.error(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
      if (error?.response?.status == 401) {
        logOutApi();
      }
    }
  };

  // Add To Cart API
  const addToCartApi = async (courseId) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}/${addToCart}`,
        { course_id: courseId },
        { headers }
      );

      if (res?.data?.success) {
        toastSuccess(res?.data?.message);
        setAddCartEffect((prev) => prev + 1);
      }
    } catch (error) {
     console.error(error);
      toastError(error?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    getCoursesApi();
    setAsModule(false);
  }, [addCartEffect, removeCartEffect, category, bookMarkEffect]);

  const [btn, setBtn] = useState(0);

  const bookmarkHandler = (e, data) => {
    e.stopPropagation();
    setSelectedCourse(data);
    setShow(true);
  };

  const addToCartHandler = (e, data) => {
    e.stopPropagation();
    addToCartApi(data?._id);
  };

  useState(() => {
    getCategoriesApi();
  }, []);

  const type = ["new", "ongoing", "completed"];
  return (
    <Fragment>
      <section className="course_list">
        <div className="content_wrap">
          <div className="filter_pils">
            {catLoader ? (
              <p>Categories is Loading...</p>
            ) : categoryList?.length > 0 ? (
              <Fragment>
                <div
                  className={`pill ${!category ? "active" : ""}`}
                  onClick={() => navigate("/auth/courses")}
                >
                  <p>All</p>
                </div>
                {categoryList.map((data) => {
                  return (
                    <div
                      className={`pill ${
                        category === data?._id ? "active" : ""
                      }`}
                      key={data._id}
                      onClick={() => navigate(`/auth/courses/${data?._id}`)}
                    >
                      <p>{data.name}</p>
                    </div>
                  );
                })}
              </Fragment>
            ) : (
              <p>Nothing to See Here…</p>
            )}
          </div>

          <div className="tabs_wrap">
            <button
              type="button"
              className={btn === 0 ? "active" : ""}
              onClick={() => setBtn(0)}
            >
              New
            </button>
            <button
              type="button"
              className={btn === 1 ? "active" : ""}
              onClick={() => setBtn(1)}
            >
              Ongoing
            </button>
            <button
              type="button"
              className={btn === 2 ? "active" : ""}
              onClick={() => setBtn(2)}
            >
              Completed
            </button>
          </div>

          {loader ? (
            <PageLoader />
          ) : courses?.filter((data) => data?.user_status === type[btn])
              ?.length > 0 ? (
            <div className="course_grid">
              {courses
                ?.filter((data) => data?.user_status === type[btn])
                ?.map((data, j) => {
                  return (
                    <Fragment key={data._id}>
                      <CourseCard
                        {...data}
                        index={j}
                        cartBtn={!data?.is_cart}
                        bookmark={btn === 0 ? true : false}
                        ongoing={btn === 1 || btn === 2 ? true : false}
                        filledBookmark={data?.is_bookmarked}
                        bookmarkHandler={(e) => bookmarkHandler(e, data)}
                        addCartHandler={(e) => addToCartHandler(e, data)}
                      />
                    </Fragment>
                  );
                })}
            </div>
          ) : (
            <p className="mt-5 text-center">Nothing to See Here…</p>
          )}
        </div>
      </section>
      <RemoveBookmarkModal
        show={show}
        data={selectedCourse}
        onHide={() => setShow(false)}
      />
    </Fragment>
  );
};

export default CourseList;
